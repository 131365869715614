<template>
  <div class="container" v-if="!isLoading.value">
    <label for="row-select" class="mb-5">
      Releases per row
      <select v-model="releasesPerRow" id="row-select">
        <option v-for="rowOption in rowOptions.values()"
                :key="rowOption.value"
                :value="rowOption.value"
        >
          {{ rowOption.text }}
        </option>
      </select>
    </label>

    <div
      :class="`row row-cols-${releasesPerRow} mb-5`"
      v-for="(releaseRow, rowIdx) in releaseTable" :key="rowIdx">
      <div class="col" v-for="(release, colIdx) in releaseRow" :key="colIdx">
            <disco-card
              :title="release.basic_information.title"
              :id="release.id"
              :cover_art_url="release.basic_information.cover_image"
              :year="release.basic_information.year"
              :master_id="release.basic_information.master_id"
            ></disco-card>
      </div>
    </div>
  </div>
  <button v-if="hasNextPage"
    @click="fetchNextPage()"
    class="mb-4"
  >
    Load More
  </button>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { getReleases } from '@/services/discogs';
import DiscoCard from '@/components/DiscoCard.vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import get from 'lodash.get';

// reactive state
const isLoading = ref(true);
const releasesArray = ref([]);
const paging = ref({});
const currentPage = ref(1);
const hasNextPage = computed(() => currentPage.value < get(paging.value, 'pages'));
const releasesPerRow = ref(3);
// const selected = ref('A')

const rowOptions = ref([
  { text: 'One', value: 1 },
  { text: 'Two', value: 2 },
  { text: 'Three', value: 3 },
]);

onMounted(async () => {
  isLoading.value = true;
  const { pagination, releases } = await getReleases();
  isLoading.value = false;
  releasesArray.value = releases;
  paging.value = pagination;
  // default values on page load
  currentPage.value = 1;
  releasesPerRow.value = 3;
});

const releaseTable = computed(() => releasesArray.value.reduce((acc, n, i) => {
  // eslint-disable-next-line no-unused-expressions
  i % releasesPerRow.value ? acc[acc.length - 1].push(n) : acc.push([n]);
  return acc;
}, []));

// helper methods
async function fetchNextPage() {
  const pageToFetch = currentPage.value + 1;
  isLoading.value = true;
  const { pagination, releases } = await getReleases(pageToFetch);
  isLoading.value = false;
  currentPage.value += 1;
  releasesArray.value = [...releasesArray.value, ...releases];
  paging.value = pagination;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
