// eslint-disable-next-line import/no-extraneous-dependencies
const axios = require('axios');

const url = 'https://us-central1-discogs-c83c8.cloudfunctions.net';
const apiClient = axios.create({
  baseURL: url,
});

const getReleases = async (pageToFetch) => {
  const defaultQs = '?limit=25';
  const qs = pageToFetch ? `${defaultQs}&page=${pageToFetch}` : defaultQs;
  const releaseUrl = `getReleases${qs}`;
  const { data } = await apiClient.get(releaseUrl);
  return data;
};

const getUriFromMaster = async (masterId) => {
  const { data } = await apiClient.get(`getMasterUrl/${masterId}`);
  return data;
};

module.exports = {
  getReleases,
  getUriFromMaster,
};
