<template>
  <div class="card" >
    <img class="card-img-top" :key="flipCard"
         @click="flipCard(props.master_id)"
         @keyup.enter="flipCard(props.master_id)"
         :src="props.cover_art_url"
         :alt="props.title"
         loading="lazy">
    <div class="card-body">
      <h5 class="card-title">{{ props.title }}</h5>
      <p class="card-text">Released in <b>{{ props.year }}</b></p>
    </div>
  </div>
</template>

<!-- possible iframe to use later-->
<!--<iframe id="ifrm" src="/ami.html" width="500" height="500"></iframe>-->

<script setup>
import { onMounted } from 'vue';
import { getUriFromMaster } from '@/services/discogs';

const props = defineProps({
  title: String,
  id: Number,
  cover_art_url: String,
  genres: Array,
  year: Number,
  master_id: String,
});

// lifecycle hooks
onMounted(() => {
});

async function flipCard(event) {
  const { uri } = await getUriFromMaster(event);
  window.open(uri, '_blank', 'noreferrer');
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
